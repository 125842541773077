/* import jetbrains mono */
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;700&display=swap');

body {
  background: rgb(14, 11, 11);
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  cursor: none;
  user-select: none;
  overflow: hidden;
}

.magnifier {
  box-shadow: inset 0 0 30px 50px rgb(128, 124, 113);
}

.magnifier:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 200px;
  background-color: #000;
  border-radius: 10px 10px 0 0;
  bottom: 6px;
  left: 60%;
  transform-origin: center bottom;
  transform: rotate(-200deg);
}

.burn {
  background-color: red !important;
  animation: fade-out 1s linear 1 forwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.grain {
  background-image: url("data:image/svg+xml,%3C!-- svg: first layer --%3E%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='4' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
}
